import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector(props) {
  const { onValueChanged } = props; 
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
                      onValueChanged(language);
  };
      


  return (
    <div className="Languageselector">
      <span onClick={() => changeLanguage('nl')} className="fi fi-nl  " title="Nederlands"></span>
      <span onClick={() => changeLanguage('en')} className="fi fi-gb  " title="English"></span>
      <span onClick={() => changeLanguage('pl')} className="fi fi-pl  " title="Polski"></span>
    </div>
  );
  
}

export default LanguageSelector;
