import React, { Component } from 'react';
import { Container } from 'reactstrap';
import  NavMenu from './NavMenu';
import { useLocation } from 'react-router-dom';

export function Layout({children})  {
  
    var location = useLocation();
    return (
      <div>
         <NavMenu />
          <Container>
            <div className='bodyContent'>
            {children}
            </div>
          </Container>
      </div>
    );
  }

