import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Survey from './Survey';
import { addAnswers, fetchParticipantData, loginUser } from '../backendService';
import './../custom.css';
import { useTranslation } from 'react-i18next';
import i18n from "./i18n";
import LanguageSelector from './languageSelector';
import i18next from 'i18next';

export default function Participant(props) {
  const [participantId, setParticipantId] = useState(null);
  const [currentPassword, setCurrentPasswordInput] = useState("");
  const [participantData, setParticipantData] = useState(null);
  const [LoginFailureMessage, setLoginFailureMessage] = useState(false);
  const [loggedin, setLoggedin] = useState(false);
  const [lang, setLang] = useState("");

  const { t } = useTranslation();

  useEffect(async () => {
    try {
      const pathname = props.location.pathname;
      const extractedParticipantId = pathname.match(/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/);

      if (extractedParticipantId) {
        const participantId = extractedParticipantId[0];
        setParticipantId(participantId);
        const participantData = await fetchParticipantData(participantId, lang);  
        if (participantData.status === 401) {
          setLoggedin(false);
        }else{
          setParticipantData(participantData);
          setLoggedin(true);
        }
      }
      else{
        setLoggedin(false);
        //Set message "Geen geldige url"
      }

    } catch (error) {
      debugger; // Overweeg om in plaats hiervan een foutmelding weer te geven.
    }
  }, [props.location.pathname, loggedin, lang]);


  useEffect(async()=> {
    // useTranslation.language;
    setLang(i18n.language.split('-')[0]); // use 'en' from 'en-US'
    // i18next.languages;
  }, [i18n.language]);

  const handleInputChange = (event) => {
    const value = event.target.value.trim();
    setCurrentPasswordInput(value);
    setLoginFailureMessage(false);
  }
  
  const handleCompletion = async (answers) => {
    await addAnswers(answers, true);  
  }  
  
  const handleValuesChanged = async (answers) => {
    await addAnswers(answers, false);  
  }

  async function handleLogin(event) {
    event.preventDefault();
    if(participantId)
    {
      var result = await loginUser({username:participantId, password:currentPassword});
      if(result && result.status && result.status === 200){
        localStorage.setItem('jwtToken', result.data);          
        setLoggedin(true);
      }
      else {
        setLoginFailureMessage(true);
      }
    }
  }    

  function changeLanguage(language){
    i18n.changeLanguage(language);
    setLang(language);
  }

  const getLoginView = () => {
    return (
      <div>    
        <div className="header-container">
          <h1>Beste collega,</h1>
          <LanguageSelector onValueChanged={(language) => changeLanguage(language)} />
        </div>
      <p>{t("welcome_assessment_intro")}</p>
      <p>{t("welcome_assessment_anonymity")}</p>
      <p>{t("welcome_assessment_access")}</p>
          
        {LoginFailureMessage && <div className="red">Niet correct, probeer het opnieuw.</div>}
        <form className='hc-custom-form'>
          <input type="text" onChange={handleInputChange}
          onKeyDown={(event) => {
            if(event.key === 'Enter') {
              handleInputChange(event);
              handleLogin(event);
            }
        }}/>
          <button type="button" className="btn btn-primary" onClick={handleLogin}>Ok</button>
        </form>
      </div>
    );
  }


  const getSurveyData = () => {
    if (participantData){
    return (
      <div>
        <div className="header-container">
          <h1>{participantData.organisationName}</h1>
          <LanguageSelector onValueChanged={(language) => changeLanguage(language)} />
        </div>
        <p>{t("SurveyHeading", {organisationName: participantData.organisationName} )} <br/>
        {t("SurveyHeading_announce")}</p>

        <Survey modeldata={participantData.survey} answers={participantData.answersJson} onComplete={handleCompletion} onValuesChanged={handleValuesChanged} lang={i18n.language} />
        
      </div>
    );
    }
    else return null;
  }
  return loggedin ? getSurveyData() : getLoginView()
  
}
