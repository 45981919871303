//export enum SoftControl {
//    Helderheid = 10,
//    Voorbeeldgedrag = 20,
//    Betrokkenheid = 30,
//    Uitvoerbaarheid = 40,
//    Transparantie = 50,
//    Bespreekbaarheid = 60,
//    Aanspreekbaarheid = 70,
//    Handhaving = 80,
//}

export enum SoftControl {
    Bekendheid = 10,
    Rolmodel = 20,
    Aandacht = 30,
    Effectiviteit = 40,
    Integriteit = 50,
    Veiligheid = 60,
    Toegankelijkheid = 70,
    Optreden = 80,
}


//Softcontrols KMPG	        Softcontrols Human Core	    KPMG	    Human Core
//Helderheid	            Bekendheid	                Preventie	Inspireren
//Voorbeeldgedrag	        Rolmodel		
//Betrokkenheid	            Aandacht		
//Uitvoerbaarheid	        Effectiviteit		
//Transparantie	            Integriteit	            Detectie	Beleven
//Bespreekbaarheid          Veiligheid		
//Aanspreekbaarheid         Toegankelijkheid	        Response	Acteren
//Handhaving	            Optreden		
