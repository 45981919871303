import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  // Method to get the querystring parameter
  getQueryParam(name) {
    const params = new URLSearchParams(window.location.search);
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    const param = params.get(name);
  // Check if parameter is a valid GUID
  if (param && guidRegex.test(param)) {
    return param;
  }

  return null;
}

  render () {
    const pid = this.getQueryParam('pid');

    return (
      <div>
        <h1>Beste medewerker,</h1>
        <p>Werkplezier staat bij ons voorop! Om te weten hoe jullie dit ervaren in het bedrijf is het van belang dat er een analyse wordt gedaan. Wij vragen jullie medewerking met dit onderzoek. Het is belangrijk dat iedereen met dit onderzoek mee doet om een zo goed mogelijke analyse te hebben.</p>
        <p>Human Core doet een volledig anoniem onderzoek. Dus je naam, emailadres, afdeling of iets anders wordt niet bewaard.</p>
               
        {pid ? (
        <>
          <p>Succes met het invullen en uiteraard namens ons heel erg bedankt voor je medewerking.</p>
          <p>Veel werkplezier!</p>
          <a href={`/participant/${pid}`} class="btn-custom">Start onderzoek</a> 
        </>
        ) : (
          <p> 
            Klik op de link in de email die je krijgt van de werkgever om te starten met het onderzoek. 
          </p>        
        )}
      </div>
    );
  }
}
