import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) 
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // Aangepaste resources in je i18n configuratie
    resources: {
    en: {
      translation: {
        "welcome_assessment_title": "Dear colleague,",
        "welcome_assessment_intro": "We think it is very important that you enjoy working with us. Not only because of the content of your work, but also because you have nice colleagues. To get a clear picture of how you experience working with us, We regularly conduct employee surveys and would greatly appreciate your cooperation. If everyone participates, we will get the best picture of our job satisfaction and at the same time we will be able to make improvements.",
        "welcome_assessment_anonymity": "The research we do with the help of Human Core is completely anonymous. You are not asked which department you work in and your email address or other identifiable information is not stored.",
        "welcome_assessment_access": "Enter your personal unique password below to gain access. Good luck with completing it and thank you very much for your cooperation!",
        "SurveyHeading": "Welcome to the employee survey of \"{{organisationName}}\".",
        "SurveyHeading_announce": "Below are the questions for the survey.",
      }
    },
    nl: {
      translation: {
        "welcome_assessment_title": "Beste collega,",
        "welcome_assessment_intro": "Wij vinden het van groot belang dat je het fijn vindt om bij ons te werken. Niet alleen om de inhoud van je werk, maar ook omdat je leuke collega’s hebt. Om een duidelijk beeld te krijgen hoe je het werken bij ons ervaart, doen wij regelmatig een medewerkersonderzoek en stellen wij het erg op prijs als je hieraan meewerkt. Als iedereen meedoet krijgen we het beste beeld hoe het met ons werkplezier is gesteld en zijn we tegelijkertijd in staat om verbeteringen door te voeren.",
        "welcome_assessment_anonymity": "Het onderzoek wat wij met behulp van Human Core doen is volledig anoniem. Er wordt niet gevraagd op welke afdeling je werkt en je mailadres of andere herleidbare gegevens worden niet bewaard.",
        "welcome_assessment_access": "Vul hieronder de persoonlijke unieke wachtwoord in om toegang te krijgen. Succes met het invullen en heel erg bedankt voor je medewerking!",
        "SurveyHeading":"Welkom bij het medewerkersonderzoek van \"{{organisationName}}\".",
        "SurveyHeading_announce":"Hieronder volgen de vragen van het onderzoek.",
      }
    },
    pl: {
      translation: {
        "welcome_assessment_title": "Drogi kolego,",
        "welcome_assessment_intro": "Bardzo nam zależy, abyś czuł się dobrze pracując z nami. Nie tylko ze względu na treść twojej pracy, ale także dlatego, że masz miłych kolegów. Aby uzyskać jasny obraz tego, jak doświadczasz pracy u nas, regularnie przeprowadzamy badanie wśród pracowników i bardzo cenimy sobie twoją współpracę. Gdy wszyscy wezmą udział, otrzymamy najlepszy obraz naszej satysfakcji z pracy i jednocześnie będziemy mogli wprowadzić ulepszenia.",
        "welcome_assessment_anonymity": "Badanie przeprowadzone za pomocą Human Core jest całkowicie anonimowe. Nie pytamy o to, w jakim dziale pracujesz, ani nie przechowujemy twojego adresu e-mail czy innych danych umożliwiających identyfikację.",
        "welcome_assessment_access": "Wpisz poniżej swoje unikalne hasło osobiste, aby uzyskać dostęp. Powodzenia w wypełnianiu i bardzo dziękujemy za współpracę!",
        "SurveyHeading": "Witaj w badaniu pracowniczym \"{{organisationName}}\".",
        "SurveyHeading_announce": "Poniżej znajdują się pytania badania."
      }
    }
      },
    fallbackLng: "nl", // use en if detected lng is not available
    whiteList: [
        'en', 'pl', 'nl'
      ],

    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage', 'cookie'], 
        checkWhitelist: true,
      },

    interpolation: {
      escapeValue: false, // react already safes from xss
    }
  });

export default i18n;
