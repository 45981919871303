import React, { useState } from 'react';
import { addPage } from '../../backendService';

function NewPageForm({ onSubmit }) {

  const [page, setPage] = useState({ id: 0, name: '', title: '', description: '' });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPage({ ...page, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await addPage(page);
    if (onSubmit) { onSubmit(); }
  }
  
  console.log('page:', page);

  return (
    <form className='hc-custom-form' onSubmit={handleSubmit}>
      <label>
        Id:
        <input 
          type="number" 
          name="id" 
          value={page.id} 
          onChange={handleInputChange} 
          min="0" // Optional, to prevent below zero number-input
        />
      </label><br/>
      <label>
        Naam:
        <input 
          type="text" 
          name="name" 
          value={page.name} 
          onChange={handleInputChange} 
        />
      </label><br/>
      <label>
        Titel:
        <input 
          type="text" 
          name="title" 
          value={page.title} 
          onChange={handleInputChange} 
        />
      </label><br/>
      <label>
        Beschrijving:
        <textarea 
          name="description" 
          value={page.description} 
          onChange={handleInputChange} 
        />
      </label><br/>

      <button type="submit">Verzenden</button><br/>
    </form>
  );
}

export default NewPageForm;
