import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify'; // https://chat.openai.com/share/9acc1c77-8d8b-48a3-b5de-1ab097b1ef9b
import { deletePage, fetchAllQuestionsJson, fetchPages, fetchQuestions } from "../../backendService";
import NewQuestionForm from './NewQuestionForm';
import EditQuestion from './EditQuestionForm';
import Question from './question';
import Survey from './../Survey';
import Modal from './../Modal';
import NewPageForm from './NewPageForm';
import LanguageSelector from '../languageSelector';

function ManageQuestions(props) {
    const [questions, setQuestions] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [isNewPageModalOpen, setIsNewPageModalOpen] = useState(false);
    const [questionToEdit, setquestionToEdit] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(true);
    const [allQuestionsSurvey, setAllQuestionsSurvey] = useState(null);
    const [pages, setPages] = useState(null);
    const [lang, setLang] = useState("nl")

    const refreshQuestions = async () => {
      try{
        setIsEditModalOpen(false);
        setIsNewModalOpen(false);
        const questionsData = await fetchQuestions(lang);
        if (questionsData.status)
        {
          if (questionsData.status === 401) {
            props.history.push('/login/q');
          } else {
            toast.error(questionsData.message);}
        } else { 
          setPages(await fetchPages());
          setQuestions(questionsData);
          const allQuestionsSurveyData = await fetchAllQuestionsJson();
          if(!allQuestionsSurveyData.status)
          {
            setAllQuestionsSurvey(allQuestionsSurveyData);
          }        
        }
      }
      catch(error)
      {
        toast.error(error.message);
      }
    }

    useEffect(() => {
        if (shouldRefresh) {
          refreshQuestions();
          setShouldRefresh(false); // Reset de refresh-vlag
        }
      }, [shouldRefresh]);

    const editQuestion = (question) => {
        setquestionToEdit(question);
        setIsEditModalOpen(true);
    }

    const handleDelete = async (pageId) => {
      // Confirmation dialoog
      const isConfirmed = window.confirm("Weet je zeker dat je deze pagina met alle gekoppelde vragen en antwoorden wilt verwijderen?");
      if (!isConfirmed) {
          return; 
      }
        try {
            await deletePage(pageId);
            setShouldRefresh(true)
        } catch (error) {
            console.error("Error deleting question:", error);
        }
    }
      
      
    const changeLanguage = (language) => {

      questions && questions.forEach((question) => {
        var currentTranslation = question.translations.find(t => t.language === language);    
        // question.localizedTitle = currentTranslation?.title;
        // question.locale = language;
        question.currentTranslation = currentTranslation
        setLang(language);        
      });
    }

    return (
        <>

          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"/>

          <div>
              <div className="header-container">
                <h2>Beheer van vragen</h2>
                <LanguageSelector onValueChanged={(language) => changeLanguage(language)} />
              </div>

              <table className='questionsTable'>
                  <thead>
                      <tr>
                          <th></th>
                          <th></th>
                          <th>Pagina</th> 
                          <th>Vraag</th>
                          {/* <th>\keuzes</th> */}
                      </tr>
                  </thead>
                  <tbody>
                      {console.log("questions before listing: ", questions)}
                      {questions && [...questions].sort((a, b) => a.pageId - b.pageId).map(question => (
                          <Question key={question.id} question={question} onChanged={refreshQuestions} onEdit={editQuestion} />
                      ))}
                  </tbody>
              </table>
          </div>

          <form className='hc-custom-form'>
            <button type="button" onClick={() => setIsNewModalOpen(true)}>Vraag +</button>
          </form>
          <hr></hr>


          <table>
            <tbody>
              {pages && [...pages].sort((a, b) => a.pageId - b.pageId).map(page => (
                <tr key={page.id}>
                  <td><span className="icon delete-icon" onClick={() => handleDelete(page.id)}><i className="fas fa-trash-alt"></i></span></td>
                  <td>{page.id}</td>
                  <td>{page.name}</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</td>
                  <td>{page.description}</td>
                </tr>
              ))}
            </tbody>
          </table>


          <form className='hc-custom-form'>
          <button type="button" onClick={() => setIsNewPageModalOpen(true)}> Page +</button>
          </form>
          <hr></hr>
          <Survey data={allQuestionsSurvey} />    

          <Modal isOpen={isNewModalOpen} onClose={() => setIsNewModalOpen(false)} >
            <NewQuestionForm pages={pages} onFormSubmit={() => setShouldRefresh(true)}/>
          </Modal>
          <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} >
            <EditQuestion questionToEdit={questionToEdit} pages={pages} onFormSubmit={() => setShouldRefresh(true)} lang={lang}/>
          </Modal>
          <Modal isOpen={isNewPageModalOpen} onClose={() => setIsNewPageModalOpen(false)} >
            <NewPageForm onSubmit={() => setShouldRefresh(true)}/>
          </Modal>
                
        </>         
    );
  }


export default ManageQuestions;

