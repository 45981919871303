import React, { Component } from 'react';
import { deleteAssessment } from '../../backendService';

function AssessmentCard ({ assessment, onChanged, onMail, onAddParticipant }) {
    // Bepaal de status van de assessment
    let status;
    let now = new Date();  

    const maanden = ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."];
    const statusses = ["Aangemaakt", "Mailadressen gereed", "Ingepland", "Lopend", "Gereed"];


    const handleDelete = async () => {
        // Confirmation dialoog
        const isConfirmed = window.confirm("Weet je zeker dat je dit assessment met alle data wilt verwijderen?");
        if (!isConfirmed) {
            return; 
        }
        try {
            console.log("Attempting to delete assessment with ID:", assessment.id);
            await deleteAssessment(assessment.id);   
            console.log("Assessment deleted successfully");    
            onChanged();
        } catch (error) {
            console.error("Error deleting assessment:", error);
        }
    };

    const handleMail = async () => {
        onMail();        
    };
    
    const handleAddParticipant= async () => {
        onAddParticipant();        
    };

    // Formatteer de datum
    const startDate = new Date(assessment.startDate);
    const maand = maanden[startDate.getMonth()];
    const formatterrdDate = startDate.toDateString();
    const formatted3Date = `${maand}${startDate.getFullYear()}`;

    let formattedDate = startDate.toLocaleDateString('nl-NL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

    let ingevuldPercentage = assessment.participantCount === 0 ? 0 : 
      ((assessment.participantCountCompleted + (assessment.participantCountPartlyCompleted / 2)) / assessment.participantCount) * 100;
  

    return (<div className="assessment-card">
            <h4>{assessment.name} {formattedDate}</h4>
            <p><b> Ingevuld: {ingevuldPercentage.toFixed(0)}%</b></p>
            <p>Deelnemers: {assessment.participantCount}</p>
            <p> - niet gestart: {assessment.participantCount - assessment.participantCountPartlyCompleted-assessment.participantCountCompleted}</p>
            <p> - bezig: {assessment.participantCountPartlyCompleted}</p>
            <p> - gereed: {assessment.participantCountCompleted}</p>
           
            {/* <p>Status: {statusses[assessment.status]}</p> */}
            <div className='icons'>
                <span className="icon delete-icon" onClick={() => handleDelete()}><i className="fas fa-trash"></i></span>
                <span className="icon mail-icon" onClick={() => handleMail()}><i className="fas fa-envelope"></i></span>
                <span className="icon plus-icon" onClick={() => handleAddParticipant()}><i className="fas fa-plus"></i></span>
            </div>
        </div>
    );
};

export default AssessmentCard;
