import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import Participant  from './components/Participant';
import './custom.css'
import ManageAssessments from './components/assessments/ManageAssessments';
import ManageOrganisations from './components/Organisations';
import Login from './components/Login2';
import ManageQuestions from './components/questions/ManageQuestions';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Dashboard from './components/Dashboard';


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <>
      <ToastContainer />
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/participant' component={Participant} />
        <Route path='/organisations' component={ManageOrganisations} />
        <Route path='/manage/assessments' render={routeProps => <ManageAssessments {...routeProps} />} />
        <Route path='/manage/questions' render={routeProps => <ManageQuestions {...routeProps} />} />
        <Route path='/dashboard' component={Dashboard} />

        <Route path='/login' component={Login} />
      </Layout>
      </>
    );
  }
}
