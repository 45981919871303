import React, { useState } from 'react';
import { loginUser } from '../backendService'
import { withRouter } from 'react-router-dom';


function Login(props) {
    const [loginData, setLoginData] = useState({
      username: '',
      password:'',
      organisation:''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLoginData({ ...loginData, [name]: value });
      };
    
    const handleSubmit = async (event) => {      
      event.preventDefault();
        var result = await loginUser(loginData);
        if(result && result.status && result.status === 200){
          localStorage.setItem('jwtToken', result.data);   
          if (props.location.pathname == "/login/a") props.history.push('/Manage/Assessments');
          if (props.location.pathname == "/login/q") props.history.push('/Manage/questions');
        }
      }
    
      return (
        <>
        <form className='hc-custom-form' onSubmit={handleSubmit}>
        <p>Log in als beheerder:</p>
            <input 
              type="text" 
              name="username" 
              value={loginData.username} 
              onChange={handleInputChange} 
              placeholder='Gebruikersnaam'
            />    
            <input 
              type="password" 
              name="password" 
              value={loginData.password} 
              onChange={handleInputChange} 
              placeholder='Wachtwoord'
            />        
          <button type="submit">Verzenden</button><br/>
        </form>
        </>
      );
    }

    export default withRouter(Login);