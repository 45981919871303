import React, { useEffect, useState } from 'react';
import { SoftControl } from './SoftControl.ts';

function BaseQuestionForm({ pages, initialData, onSubmit, lang }) {
  console.log('BaseQuestionForm: Initial data:', initialData);
  console.log('BaseQuestionForm: pages:', pages);

  const [question, setQuestion] = useState(initialData);
  // const [translation, setTranslation] = useState(null);
  

  // useEffect(() => {
  //   if (question && question.translations) {
  //     // var translation = question.translations.find(t => t.language === lang); 
  //     setTranslation(translation);   
  //   }
  // }, [question]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setQuestion({ ...question, [name]: value });
  };

  const handleTranslationInputChange = (event) => {
    const { name, value } = event.target;

    const translationIndex = question.translations.findIndex(
      (t) => t.language === lang
    );

    const updatedTranslations = [...question.translations];
    if (translationIndex >= 0) {
      updatedTranslations[translationIndex] = {
        ...updatedTranslations[translationIndex],
        [name]: value,
      };
    }

    // Update de question state met de bijgewerkte translations array
    setQuestion({
      ...question,
      translations: updatedTranslations
    });
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const questionData = {
      ...question,
      name: question.title
    };      
  
    if (onSubmit) { onSubmit(questionData); }
  }
  
  console.log('question:', question);

  return (
    <>
    <form className='hc-custom-form' onSubmit={handleSubmit} width="100%">
    <label>
      Vraag:
      <input 
        type="text" 
        name="title" 
        value={question.title} 
        onChange={handleInputChange} 
        style={{ width: '800px' }} 
      />
    </label><br/>
    {lang && lang!="nl" && <label>
        Vraag-{lang}:
        <input 
          type="text" 
          name="title" 
          value={question.translations.find(t => t.language === lang)?.title || ''} 
          onChange={handleTranslationInputChange} 
          style={{ width: '800px' }} 
        />
      </label>}<br/>

      <label>
        Type:
        <select 
          name="type" 
          value={question.type}
          onChange={handleInputChange}
        >
          <option value="rating">Rating</option>
          <option value="radiogroup">Radiogroup</option>
          <option value="dropdown">Dropdown</option>
        </select>
      </label><br/>
      {(question.type === 'radiogroup' || question.type === 'dropdown') && (
        <>
          <label>
            Keuzes (gescheiden door komma's):
            <input 
                type="text" 
                name="choicesString" 
                value={question.choicesString} 
                onChange={handleInputChange} 
                placeholder="Bijv.: keuze1, keuze2, keuze3"
            />
          </label><br/>
      </>
      )}
      {question.type === 'rating' && (
        <>
        
        <div style={{display: "flex"}}>
            <div>
              <label>
                Label min:
                <input 
                  type="text" 
                  name="minRateDescription" 
                  value={question.minRateDescription} 
                  onChange={handleInputChange} 
                  placeholder="Bijv.: Nooit"
                />
              </label>
            </div>
            <div>
              {lang && lang!="nl" && <label>
                Label min-{lang}:
                <input 
                  type="text" 
                  name="minRateDescription" 
                  value={question.translations.find(t => t.language === lang)?.minRateDescription || ''} 
                  onChange={handleTranslationInputChange} 
                  placeholder="Bijv.: Nooit"
                />
              </label>}
            </div>
          </div>
          <div style={{display: "flex"}}>
            <div>
              <label>
                Label max:
                <input type="text" name="maxRateDescription" value={question.maxRateDescription} onChange={handleInputChange} placeholder="Bijv.: Altijd" />
              </label>
            </div>
            <div>
              {lang && lang!="nl" && <label>
                Label max-{lang}:
                <input type="text" name="maxRateDescription" value={question.translations.find(t => t.language === lang)?.maxRateDescription || ''} onChange={handleTranslationInputChange} placeholder="Bijv.: Altijd" />
              </label>}
            </div>
          </div>

        </>
      )}

      <label>
        Pagina:
        <select 
          name="pageId" 
          value={question.pageId}
          onChange={handleInputChange}
        >
          {pages && pages.map((page) => (
            <option key={page.id} value={page.id}>
              {page.title}
            </option>
          ))}
        </select>
      </label><br/>

      <label>
        SoftControl:
        <select 
          name="softControl" 
          value={question.softControl}
          onChange={handleInputChange}
        >
          {Object.entries(SoftControl)
            .filter(([key, value]) => typeof value === 'number')
            .map(([key, value]) => (
            <option key={value} value={value}>
              {key}
            </option>
          ))}
        </select>

      </label><br/>


      <button type="submit">Verzenden</button><br/>
    </form>
    </>
  );
}

export default BaseQuestionForm;
