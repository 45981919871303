import React, { useState } from 'react';
import { addQuestion } from '../../backendService';
import BaseQuestionForm from './BaseQuestionForm';

function NewQuestionForm({ onFormSubmit, pages }) {
  const initialdata = {
    title: '',
    type: 'rating',
    isRequired: false,
    minRateDescription: '',
    maxRateDescription: '',
    choicesString: ''
  };

  const handleNewSubmit = (question) => {
    
    const questionData = {
      ...question,
      name: question.title
    };
      
    addQuestion(questionData);
  
    if (onFormSubmit) { onFormSubmit(); }
  }
  

  return (
  <>
  
    <h4>Nieuwe vraag</h4>
    <BaseQuestionForm initialData={initialdata} pages={pages} onSubmit={handleNewSubmit}/>
  
  </>
  );
}

export default NewQuestionForm;
