import React, { useState } from 'react';
import { initiateAssessment } from '../../backendService';

const InitiateAssessmentForm = ({assessmentId, onFormSubmit}) => {

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            const res = await initiateAssessment(assessmentId);
            console.log(res.data);
            if (onFormSubmit) {onFormSubmit();}  
        } catch(err) {
            console.error(err);
        }
    };

    return (
        <><h3>Initieren assessment:</h3>
        <form className='hc-custom-form' onSubmit={submitForm}>
            <button type="submit">Submit</button>
        </form>
        </>
    );
};

export default InitiateAssessmentForm;
