import React, { useState, useEffect } from 'react';
import AssessmentCard from './AssessmentCard';
import NewAssessmentForm from './NewAssessmentForm';
import InitiateAssessmentForm from './InitiateAssessmentForm';
import AddEmailsForm from './AddEmailsForm';
import CreateRemindersForm from './CreateRemindersForm';
import { fetchAssessments, loggedinUserData } from '../../backendService';
import './AssessmentCard.css';
import AddParticipantForm from './AddParticipantForm';
import Modal from './../Modal';

function ManageAssessments(props) {
    const [loading, setLoading] = useState(true);
    const [assessments, setAssessments] = useState([]);
    const [userData, setUserData] = useState([]);
    const [mfAssessmentId, setmfAssessmentId] = useState(-1);    
    const [isMailReminderModalOpen, setIsMailReminderModalOpen] = useState(false);
    const [isAddParticipantModalOpen, setIsAddParticipantModalOpen] = useState(false);

    useEffect(() => {
        refreshAssessments();
    }, []);

    const refreshAssessments = async () => {
        try {

            const userData = await loggedinUserData();
            if (userData.status === 401 ||userData.status === 404) {
                props.history.push('/login/a');
            } else {
                setUserData(userData);
                const data = await fetchAssessments();
                setAssessments(data);
                setLoading(false);
                setIsMailReminderModalOpen(false);
                setIsAddParticipantModalOpen(false);
            }            
        } catch (error) {
            debugger; // Overweeg om in plaats hiervan een foutmelding weer te geven.
        }
    };
    
    const handleOkClick = () => {
        // Hier zou logica moeten zijn die 'ingelogd' instelt op basis van het ingevoerde wachtwoord.
        // Op dit moment gebeurt er niets met de input.
    };

    const ShowAssessments = () => {
        let content;
        let assessment;

        if (assessments && assessments.filter(a => a.status < 2).length === 0) {
            content = (
                <>
                    <h3>Toevoegen assessment:</h3>
                    <NewAssessmentForm onFormSubmit={refreshAssessments} />
                </>
            );
        } else {
            let assessment = assessments && assessments.length > 0 && assessments.find(a => a.status < 2);

            content = (
                <>
                    {assessment && assessment.status === 0 && <AddEmailsForm assessmentId={assessment.id} onFormSubmit={refreshAssessments} />}
                    {assessment && assessment.status === 1 && <InitiateAssessmentForm assessmentId={assessment.id} onFormSubmit={refreshAssessments} />}
                    {!assessment && <NewAssessmentForm onFormSubmit={refreshAssessments} />}
                </>
            );
        }

        return (
            <>
            <div>
                <h1>{userData.organisationName}</h1>
                <hr /><br />
                {assessment && <AssessmentCard assessment={assessment} onMail={() => setmfAssessmentId(assessment.id)} />}
                {content}
                <div className="spacer"><br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;</div>
                <h3>Assessment Historie:</h3>
                <div className="assessment-container">
                    {assessments.length > 0 && assessments
                        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                        .map((assessment) => (
                            <AssessmentCard key={assessment.id} assessment={assessment} onChanged={() => refreshAssessments()} 
                                onMail={() => {
                                    setmfAssessmentId(assessment.id);
                                    setIsMailReminderModalOpen(true);
                                }}
                                onAddParticipant={() => {
                                    setmfAssessmentId(assessment.id);
                                    setIsAddParticipantModalOpen(true);
                                }}
                            />
                        ))}
                </div>
            </div>
            <Modal isOpen={isAddParticipantModalOpen} onClose={() => setIsAddParticipantModalOpen(false)} >
                <AddParticipantForm assessmentId={mfAssessmentId} onFormSubmit={refreshAssessments}/>    
            </Modal>
            <Modal isOpen={isMailReminderModalOpen} onClose={() => setIsMailReminderModalOpen(false)} >  
                <CreateRemindersForm assessmentId={mfAssessmentId} onFormSubmit={refreshAssessments}/>
            </Modal>
            </>
        );
    };

    if (loading) return <p>Loading...</p>;
    return ShowAssessments();
}

export default ManageAssessments;
