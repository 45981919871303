import React, { useState } from 'react';
import { addParticipant } from '../../backendService';

const AddParticipantForm = ({ assessmentId, onFormSubmit }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setError('Voer een geldig e-mailadres in.');
            return;
        }

        try {
            const res = await addParticipant(assessmentId, email);
            console.log(res.data);
            if (onFormSubmit) onFormSubmit();
            setEmail('');
            setError('');
        } catch (err) {
            console.error(err);
            setError('Fout bij het toevoegen van deelnemer.');
        }
    };

    return (
        <>
            <h3>Toevoegen deelnemer-emailadres:</h3>
            <form className='hc-custom-form' onSubmit={submitForm}>
                <input 
                    type="text" 
                    name='email' 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Submit</button>
                {error && <div>{error}</div>}
            </form>
        </>
    );
};

export default AddParticipantForm;
