import React, { useEffect, memo, useState } from 'react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label, Text } from 'recharts';
import './../custom.css';
import './dashboard.css';
import { fetchMaturityData, loggedinUserData } from '../backendService';

function Dashboard(props) {

    // Lazy initialization for state
    const [data, setData] = useState(null);
    const [responsRates, setResponsRates] = useState(null);
    const [labels, setLabels] = useState(null);
    const [userData, setUserData] = useState([]);
    const [graphChoice, setGraphChoice] = useState("A");
    const [maturity, setMaturity] = useState(null);


    // Fetching maturity data and handling promise
    useEffect(() => {
        const fetchData = async () => {
            try {
                const newData = await fetchMaturityData();
                const data = convertData(newData);
                const responsRates = convertResponsRates(newData);
                setData(data);
                setResponsRates(responsRates);
                setLabels(newData[0].names);

                const userData = await loggedinUserData();
                if (userData.status === 401 || userData.status === 404) {
                    props.history.push('/login/a');
                } else {
                    setUserData(userData);
                }
            } catch (err) {
                console.log('Unauthorized or other error:', err);
            }
        };
        
        fetchData();
    }, []);

    useEffect(() => {
        // CHeck if both responsRates and data contains data
        if (responsRates && data) {
            graphChoiceChanged("A");
        }
    }, [responsRates, data]); // THis useEffect executes if responsRates or data changes
    
    const convertData = (newData) => {
        const chartData = newData.map(item => {
          const values = item.maturatyValues || [];
          return {
            subject: item.softControlName,
            A: values.length > 0 ? values[0] : null,
            B: values.length > 1 ? values[1] : null,
            C: values.length > 2 ? values[2] : null,
            D: values.length > 3 ? values[3] : null,
          };
        });
      
        return chartData;
    };    
    
    const convertResponsRates = (newData) => {
        const rates = newData[0].responsRates;
        return {
            A: rates[0],
            B: rates[1], 
            C: rates[2], 
            D: rates[3]
        }      
    };

    const responsePercentage = () => {
        let percentage = "0%"; // Standaardwaarde voor het geval de berekening mislukt.

        if (responsRates[graphChoice].participantCount > 0 && !isNaN(responsRates[graphChoice].participantCountCompleted) && !isNaN(responsRates[graphChoice].participantCount)) {
            percentage = ((responsRates[graphChoice].participantCountCompleted / responsRates[graphChoice].participantCount) * 100).toFixed(1).replace(/\.?0+$/, "") + "%";
        }

        return percentage; 
    }



      
     //Maturitylevel
    const calculateAverage = (key) => {
        const sum = data.reduce((acc, item) => acc + (item[key] || 0), 0);
        return sum / data.length;
    };

    const graphChoiceChanged = (value) => {
        setGraphChoice(value);
        // setCurrentResponsRate(responsRates(value));

        const average = Math.round(calculateAverage(value));
        setMaturity(average);
    }
    if (!data || !labels) {
        return <div>Loading...</div>;
    }
   
    console.log(data);


    return (
        <>
            <h1>{userData.organisationName}</h1>
            <hr /><br />
            {/* <div className="RadarChartContainer"> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <svg width="400" height="400" xmlns="http://www.w3.org/2000/svg">
                    <image href="/HC-illustratie.png" width="400" height="400" />
                    {maturity > 0 && (
                        <g>
                            <circle cx="195" cy="200" r="75" fill="#932132" />
                            <text x="190" y="200" fontFamily="Rubik" fontSize="48" fill="#002061" textAnchor="middle" dominantBaseline="middle">
                                {maturity}
                            </text>
                        </g>
                    )}
                    </svg>


                    
                    <div style={{ width: '600px', height: '400px' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart cx="50%" cy="50%" outerRadius="89%" margin={{ top: 20, right: 60, left: 60, bottom: 20 }} data={data}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="subject" tickSize={20}/>
                                <PolarRadiusAxis angle={45} domain={[0, 5]} ticks={[0, 2, 3, 4, 5]} axisLine={true} />
                                {(graphChoice == "all" || graphChoice == 'C') && <Radar name={labels[0]} dataKey='C' stroke="#00877F" fill="#00877F" fillOpacity={0.6} legendType="plainline" />}
                                {(graphChoice == "all" || graphChoice == 'B') && <Radar name={labels[0]} dataKey='B' stroke="#932132" fill="#932132" fillOpacity={0.6} legendType="plainline" />}
                                {(graphChoice == "all" || graphChoice == 'A') && <Radar name={labels[0]} dataKey='A' stroke="#002061" fill="#002061" fillOpacity={0.6} legendType="plainline" />}
                            </RadarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {responsRates[graphChoice] && (
                <div className="scores-container">
                    {maturity > 0 && <div className="maturityScore">Volwassenheidsscore: {maturity}</div>}
                    <div className="stats-container">    
                            <div className="stats-item">
                                <div className="stats-value">{responsRates[graphChoice].participantCount}</div>
                                <div className="stats-title">Uitgenodigd</div>
                            </div>
                            <div className="stats-item">
                                <div className="stats-value">{responsRates[graphChoice].participantCountCompleted}</div>
                                <div className="stats-title">Volledig ingevuld</div>
                            </div>
                            <div className="stats-item">
                                <div className="stats-value">{responsRates[graphChoice].participantCountPartlyCompleted}</div>
                                <div className="stats-title">Deels ingevuld</div>
                            </div>
                            <div className="stats-item">
                                <div className="stats-value">{responsRates[graphChoice].participantCount - responsRates[graphChoice].participantCountCompleted}</div>
                                <div className="stats-title">Niet gestart</div>
                            </div>
                            <div className="stats-item">
                                <div className="stats-value">{responsePercentage()}</div>
                                <div className="stats-title">Percentage ingevuld</div>
                            </div>
                    </div>
                </div>
                )}
                
                


                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                    {
                        <ResponsiveContainer width="24%" height={150} >
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                                <PolarGrid />
                                <PolarRadiusAxis angle={90} domain={[0, 5]} tick={false} />
                                <Radar name={labels[3]} dataKey="C" stroke="#00877F" fill="#00877F" fillOpacity={0.6} onClick={() => graphChoiceChanged("all")} />
                                <Radar name={labels[3]} dataKey="B" stroke="#932132" fill="#932132" fillOpacity={0.6} onClick={() => graphChoiceChanged("all")} />
                                <Radar name={labels[3]} dataKey="A" stroke="#002061" fill="#002061" fillOpacity={0.6} onClick={() => graphChoiceChanged("all")} />
                                {/*<Legend />*/}
                            </RadarChart>
                        </ResponsiveContainer>
                    }
                    {labels[2] &&
                        <ResponsiveContainer width="24%" height={150}>
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data} onClick={() => graphChoiceChanged("C")}>
                                <PolarGrid />
                                <PolarRadiusAxis angle={90} domain={[0, 5]} tick={false} />
                                <Radar name={labels[2]} dataKey="C" stroke="#00877F" fill="#00877F" fillOpacity={0.6} />
                                <Legend />
                            </RadarChart>
                        </ResponsiveContainer>
                    }
                    {labels[1] &&
                        <ResponsiveContainer width="24%" height={150}>
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data} onClick={() => graphChoiceChanged("B")}>
                                <PolarGrid />
                                <PolarRadiusAxis angle={90} domain={[0, 5]} tick={false} />                                
                                <Radar name={labels[1]} dataKey="B" stroke="#932132" fill="#932132" fillOpacity={0.6} />
                                <Legend />
                            </RadarChart>
                        </ResponsiveContainer>
                    }
                    {labels[0] &&
                        <ResponsiveContainer width="24%" height={150}>
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data} onClick={() => graphChoiceChanged("A")}>
                                <PolarGrid />
                                <PolarRadiusAxis angle={90} domain={[0, 5]} tick={false} />
                                <Radar name={labels[0]} dataKey="A" stroke="#002061" fill="#002061" fillOpacity={0.6} />
                                <Legend />
                            </RadarChart>
                        </ResponsiveContainer>
                    }
                </div>
            {/* </div> */}
        </>
    );
}

export default memo(Dashboard);
