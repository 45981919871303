import React, { useEffect, useState } from 'react';
import { deleteQuestion } from '../../backendService';

function Question ({ question, onChanged, onEdit }) {

    const handleEdit = () => {
        onEdit(question);
    };

    const handleDelete = async () => {
        // Confirmation dialoog
        const isConfirmed = window.confirm("Weet je zeker dat je deze vraag met alle beantwoordingen wilt verwijderen?");
        if (!isConfirmed) {
            return; 
        }

        try {
            console.log("Attempting to delete question with ID:", question.id);
            await deleteQuestion(question.id);   
            console.log("Question deleted successfully");    
            onChanged();
        } catch (error) {
            console.error("Error deleting question:", error);
        }
    };
    
    // return (
    //     <>
    //     <span className="icon edit-icon" onClick={() => handleEdit()}><i className="fas fa-pencil-alt"></i></span>
    //     <span className="icon delete-icon" onClick={() => handleDelete()}><i className="fas fa-trash-alt"></i></span>
    //     [ {question.pageTitle} ]  &nbsp;&nbsp;
    //     {question.title} 
    //     {['dropdown', 'radiogroup'].includes(question.type) && "  (" + question.choicesString+") "}
    //     {question.type === 'rating'  && "  ( " + question.minRateDescription  + " => " + question.maxRateDescription + " ) "}
    //     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //     <br/>
    //     </>
    // );

    return (
        <tr>
            <td><span className="icon edit-icon" onClick={() => handleEdit()}><i className="fas fa-pencil-alt"></i></span></td>
            <td><span className="icon delete-icon" onClick={() => handleDelete()}><i className="fas fa-trash-alt"></i></span></td>
            <td> {question.pageTitle} </td>
            <td>{question.title} <br />
            {question.currentTranslation?.title}
            </td>
            {/* <td>
                {['dropdown', 'radiogroup'].includes(question.type) && " (" + question.choicesString + ") "}
                {question.type === 'rating' && " ( " + question.minRateDescription + " => " + question.maxRateDescription + " ) "}
            </td> */}
        </tr>
    );
}

export default Question;
