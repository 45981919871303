import axios from 'axios';

const API_URL = 'api';
const configMultipart = {headers:{'Content-Type':'multipart/form-data'}};

// TODO axios.defaults.baseURL = 'http://localhost:5000/api'; // Zorg ervoor dat dit je correcte base URL is


const handleApiError = (error) => {
  if (error.response) {
    if(error.response.status === 401) return {status:401, message: 'unauthorized'};

    // Server reageerde met een statuscode buiten het bereik van 2xx maar niet met 401:unauthorized
    console.error('Data:', error.response.data);
    console.error('Status:', error.response.status);
    console.error('Headers:', error.response.headers);
    return {
      status: error.response.status,
      message: error.response.data.message || 'Er is iets fout gegaan.',
    };
  } else if (error.request) {
    // Verzoek is gemaakt, maar er is geen reactie ontvangen
    console.error('Request:', error.request);
    return {
      status: 500,
      message: 'Geen reactie van de server ontvangen.',
    };
  } else {
    // Iets anders is misgegaan bij het opzetten van het verzoek
    console.error('Error:', error.message);
    return {
      status: 500,
      message: 'Er is iets fout gegaan bij het maken van het verzoek.',
    };
  }
}

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwtToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});


export const fetchAssessments = async () => {
  try {
    const response = await axios.get(`${API_URL}/assessments`);
    return response.data; 
  } catch (error) {
    return handleApiError(error);
  }
}

export const createAssessment = async (assessment) => {
  try {
    const response = await axios.post(`${API_URL}/assessments/createAssessment`, assessment);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const setEmails = async (assessmentId, formData) => {
  try {
    const response = await axios.post(`${API_URL}/assessments/setEmails?assessmentId=${assessmentId}`, formData, configMultipart);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
}
export const sendReminders = async (assessmentId, formData) => {
  try {
    const response = await axios.post(`${API_URL}/assessments/SendReminders?assessmentId=${assessmentId}`, formData, configMultipart);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
}

export const initiateAssessment = async (assessmentId) => {
  try {
    const response = await axios.post(`${API_URL}/assessments/${assessmentId}/initiate`, configMultipart);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
}

export const addParticipant = async (assessmentId, email) => {
  try {
    const requestBody = JSON.stringify({ emailAddress: email });
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const response = await axios.post(`${API_URL}/assessments/${assessmentId}/addParticipant`, requestBody, config);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
}

export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/Token`, loginData);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
}

export const loggedinUserData = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/LoggedinUserData`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const fetchParticipantData = async (id, lang) => {
  try {
    
    const response = await axios.get(`${API_URL}/participant?id=${id}&language=${lang}`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const fetchQuestions = async (lang) => {
  try {
    const response = await axios.get(`${API_URL}/questions?language=${lang}`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const fetchPages = async () => {
  try {
    const response = await axios.get(`${API_URL}/questions/pages`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const fetchAllQuestionsJson = async () => {
  try {
    const response = await axios.get(`${API_URL}/questions/GetAllQuestionsJson`, {
      responseType: 'text' // Need raw stringtext here, nog jsonparsed. 
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}


export const addQuestion = async (questionData) => {
  try {
    const response = await axios.post(`${API_URL}/questions`, questionData, {
      headers: {
        'Content-Type': 'application/json'
      }});
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}


export const addPage = async (page) => {
  try {
    const response = await axios.post(`${API_URL}/questions/addpage`, page, {
      headers: {
        'Content-Type': 'application/json'
      }});
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const deleteQuestion = async (questionId) => {
  try {
    const response = await axios.delete(`${API_URL}/questions/${questionId}`);

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const deleteAssessment = async (assessmentId) => {
  try {
    const response = await axios.delete(`${API_URL}/assessments/${assessmentId}`);

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const deletePage = async (pageId) => {
  try {
    const response = await axios.delete(`${API_URL}/questions/deletepage${pageId}`);

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const editQuestion = async (questionData) => {
  try {
    const questionId = questionData.id;
    const response = await axios.put(`${API_URL}/questions/${questionId}`, questionData, {
      headers: {
        'Content-Type': 'application/json'
      }});
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const addAnswers = async (answersJsonString, iscomplete) => {
  try {
    const response = await axios.post(`${API_URL}/participant/addAnswers?completed=${iscomplete}`, { answersJson: answersJsonString }, {
      headers: {
        'Content-Type': 'application/json'
      }});
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const fetchMaturityData = async () => {
  try {
    const response = await axios.get(`${API_URL}/assessments/fetchMaturityData`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}


export const fetchOrganisations = async () => {
    try {
        const response = await axios.get(`${API_URL}/organisation`);
        return response.data;
    } catch (error) {
        return handleApiError(error);
    }
}


export const addOrganisation = async (orgName) => {
    try {
        const response = await axios.post(`${API_URL}/organisation`, { organisationName: orgName }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const deleteOrganisation = async (orgId) => {
  try {
    const response = await axios.delete(`${API_URL}/organisation/${orgId}`);

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const changeOrganisation = async (orgId) => {
    try {
        const response = await axios.post(`${API_URL}/auth/ChangeOrganisation`, orgId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
}