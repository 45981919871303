import React, { useState } from 'react';
import { setEmails } from '../../backendService';

const AddEmailsForm = ({assessmentId, onFormSubmit}) => {
    const [file, setFile] = useState(null);

    const submitForm = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('file', file);
        formData.append('assessmentid', file);

        try {
            const res = await setEmails(assessmentId, formData);
            console.log(res.data);
        
            if (onFormSubmit) {onFormSubmit();}    
        } catch(err) {
            console.error(err);
        }
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <><h3>Toevoegen email-adressen:</h3>
        <form className='hc-custom-form' onSubmit={submitForm}>
            <input type="file" onChange={onFileChange} />
            <button type="submit">Submit</button>
        </form>
        </>
    );
};

export default AddEmailsForm;
