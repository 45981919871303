import React, { useCallback, useEffect, useState } from 'react';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import "survey-core/survey.i18n";
import { Model, surveyLocalization } from 'survey-core';

function SurveyComponent({ modeldata, answers, onComplete, onValuesChanged, lang }) { 
    const [survey, setSurvey] = useState(null);

    const completeSurvey  = useCallback((survey) => {
        const results = JSON.stringify(survey.data);
        onComplete(results);
    }, []);
    
    const handleValuesChanged  = useCallback((survey) => {
        const data = survey.data;
        data.pageNo = survey.currentPageNo;

        const results = JSON.stringify(data);
        onValuesChanged(results);
    }, []);
//Limited the number of showing locales in survey.locale property editor
// surveyLocalization.supportedLocales = ["en", "de", "es", "fr"];
try {
    useEffect(() => {
        if (modeldata) {            
            const parsedData = JSON.parse(modeldata);
            console.log('parsed survey data:', parsedData);
            const newSurvey = new Model(parsedData);
            newSurvey.applyTheme({"cssVariables": {
                "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
                "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
                "--sjs-general-backcolor-dim": "rgba(243, 243, 243, 1)",
                "--sjs-general-backcolor-dim-light": "rgba(249, 249, 249, 1)",
                "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
                "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
                "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
                "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
                "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
                "--sjs-primary-backcolor": "#002061",
                "--sjs-primary-backcolor-light": "rgba(0, 32, 97, 0.1)",
                "--sjs-primary-backcolor-dark": "rgba(0, 27, 82, 1)",
                "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
                "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
                "--sjs-base-unit": "8px",
                "--sjs-corner-radius": "4px",
                "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
                "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
                "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
                "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
                "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
                "--sjs-shadow-small": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
                "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
                "--sjs-shadow-inner": "inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                "--sjs-border-light": "rgba(0, 0, 0, 0.09)",
                "--sjs-border-default": "rgba(0, 0, 0, 0.16)",
                "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
                "--sjs-special-red": "rgba(229, 10, 62, 1)",
                "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
                "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
                "--sjs-special-green": "rgba(25, 179, 148, 1)",
                "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
                "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
                "--sjs-special-blue": "rgba(67, 127, 217, 1)",
                "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
                "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
                "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
                "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
                "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
                "--sjs-article-font-xx-large-textDecoration": "none",
                "--sjs-article-font-xx-large-fontWeight": "700",
                "--sjs-article-font-xx-large-fontStyle": "normal",
                "--sjs-article-font-xx-large-fontStretch": "normal",
                "--sjs-article-font-xx-large-letterSpacing": "0",
                "--sjs-article-font-xx-large-lineHeight": "64px",
                "--sjs-article-font-xx-large-paragraphIndent": "0px",
                "--sjs-article-font-xx-large-textCase": "none",
                "--sjs-article-font-x-large-textDecoration": "none",
                "--sjs-article-font-x-large-fontWeight": "700",
                "--sjs-article-font-x-large-fontStyle": "normal",
                "--sjs-article-font-x-large-fontStretch": "normal",
                "--sjs-article-font-x-large-letterSpacing": "0",
                "--sjs-article-font-x-large-lineHeight": "56px",
                "--sjs-article-font-x-large-paragraphIndent": "0px",
                "--sjs-article-font-x-large-textCase": "none",
                "--sjs-article-font-large-textDecoration": "none",
                "--sjs-article-font-large-fontWeight": "700",
                "--sjs-article-font-large-fontStyle": "normal",
                "--sjs-article-font-large-fontStretch": "normal",
                "--sjs-article-font-large-letterSpacing": "0",
                "--sjs-article-font-large-lineHeight": "40px",
                "--sjs-article-font-large-paragraphIndent": "0px",
                "--sjs-article-font-large-textCase": "none",
                "--sjs-article-font-medium-textDecoration": "none",
                "--sjs-article-font-medium-fontWeight": "700",
                "--sjs-article-font-medium-fontStyle": "normal",
                "--sjs-article-font-medium-fontStretch": "normal",
                "--sjs-article-font-medium-letterSpacing": "0",
                "--sjs-article-font-medium-lineHeight": "32px",
                "--sjs-article-font-medium-paragraphIndent": "0px",
                "--sjs-article-font-medium-textCase": "none",
                "--sjs-article-font-default-textDecoration": "none",
                "--sjs-article-font-default-fontWeight": "400",
                "--sjs-article-font-default-fontStyle": "normal",
                "--sjs-article-font-default-fontStretch": "normal",
                "--sjs-article-font-default-letterSpacing": "0",
                "--sjs-article-font-default-lineHeight": "28px",
                "--sjs-article-font-default-paragraphIndent": "0px",
                "--sjs-article-font-default-textCase": "none"
            },
            "themeName": "default",
            "colorPalette": "light"});
            newSurvey.locale = lang;
            newSurvey.onComplete.add(completeSurvey);
            newSurvey.onValueChanged.add(handleValuesChanged);
            newSurvey.onCurrentPageChanged.add(handleValuesChanged);
            
            // Restore survey results
            if(answers) {
                const data = JSON.parse(answers);
                newSurvey.data = data;
                if (data.pageNo) {
                    newSurvey.currentPageNo = data.pageNo;
                }
            }

            setSurvey(newSurvey);
        }

        return () => {
            if (survey) {
                survey.onComplete.remove(completeSurvey);
                survey.onValueChanged.remove(handleValuesChanged);
                survey.onCurrentPageChanged.remove(handleValuesChanged);
            }
        };
    }, [modeldata, answers, lang]); // Re-run this effect when 'data' changes



    return survey && <Survey model={survey} />;
    
    
} catch (error) {    
  console.error("Er is een fout opgetreden:", error.message);
}
}


export default SurveyComponent;
