import React, { useState, useEffect } from 'react';
import { fetchOrganisations, addOrganisation, deleteOrganisation, changeOrganisation, loggedinUserData } from '../backendService';

function ManageOrganisations(props) {
    const [loading, setLoading] = useState(true);
    const [organisations, setOrganisations] = useState([]);
    const [userData, setUserData] = useState([]);
    const [newOrgName, setNewOrgName] = useState("");

    useEffect(() => {
        refreshOrganisations();
    }, []);

    const refreshOrganisations = async () => {
        try {

            const userData = await loggedinUserData();
            if (userData.status === 401 || userData.status === 404) {
                props.history.push('/login/a');
            } else {
                setUserData(userData);
                const data = await fetchOrganisations();
                setOrganisations(data);
                setLoading(false);
            }
        } catch (error) {
            debugger; // Overweeg om in plaats hiervan een foutmelding weer te geven.
        }
    };

    const handleDelete = async (orgId) => {
      // Confirmation dialoog
      const isConfirmed = window.confirm("Weet je zeker dat je deze organisatie in zijn geheel wilt verwijderen?");
      if (!isConfirmed) {
          return; 
      }
        try {
            await deleteOrganisation(orgId);
            refreshOrganisations();
        } catch (error) {
            console.error("Error deleting organisation:", error);
        }
    };

    const handleSelect = async (orgId) => {
        try {
            var result = await changeOrganisation(orgId);
            if (result && result.status && result.status === 200) {
                localStorage.setItem('jwtToken', result.data.token);
            }  
            refreshOrganisations();
        } catch (error) {
            console.error("Error deleting organisation:", error);
        }
    };
    
    const ShowOrganisations = () => {

        const handleAddOrganisation = async (e) => {
            e.preventDefault();
            if (!newOrgName) {
                alert("Voer een naam in voor de organisatie");
                return;
            }

            addOrganisation(newOrgName)

            // Voeg hier logica toe om de nieuwe organisatie toe te voegen.
            // Bijvoorbeeld, een call naar een backend service.

            setNewOrgName(""); // Reset het invoerveld na het toevoegen.            
            refreshOrganisations();
        };

        return (
            <div>
                <h1>{userData.organisationName}</h1>
                <hr /><br />
                <h2>Organisaties</h2>
                <table>
                    <tbody>
                        {organisations.map((org, index) => (
                            <tr className={userData.organisationId === org.id ? 'org-row-bold' : 'org-row'}
                             style={{ fontWeight: userData.organisationId === org.id ? 'bold' : 'normal' }} key={index} onClick={() => handleSelect(org.id)}>
                                
                                <td>{org.organisationName}</td>
                                <td>
                                    {userData.organisationId !== org.id && <span className="icon select-icon" ><i className="fa-regular fa-star"></i></span>}
                                    {userData.organisationId === org.id && <span className="icon select-icon" ><i className="fas fa-star"></i></span>}
                                </td>
                                {/* <FontAwesomeIcon icon="fa-regular fa-star" rotation={90} style={{color: "#8db159",}} /> */}
                                <td><span className="icon delete-icon" onClick={() => handleDelete(org.id)}><i className="fas fa-trash-alt"></i></span></td>
                                {/*<td>{org.location}</td>*/}
                                {/*<td>{org.contact}</td>*/}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <form className='hc-custom-form' onSubmit={handleAddOrganisation}>
                    <input
                        type="text"
                        id="newOrgName"
                        value={newOrgName}
                        onChange={(e) => setNewOrgName(e.target.value)}
                        placeholder='Toevoegen...'
                    />
                    <button type="submit">Toevoegen</button>
                </form>
            </div>
        );
    };


    if (loading) return <p>Loading...</p>;
    return ShowOrganisations();
}

export default ManageOrganisations;
