import React, { useState } from 'react';
import { createAssessment } from '../../backendService';

function NewAssessmentForm({ onFormSubmit }) {
  const [assessment, setAssessment] = useState({
    startDate: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAssessment({ ...assessment, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createAssessment(assessment);

    if (onFormSubmit) {onFormSubmit();}    
  }

  return (
    <form className='hc-custom-form' onSubmit={handleSubmit}>
      <label>
        Startdatum:
        <input 
          type="date" 
          name="startDate" 
          value={assessment.startDate} 
          onChange={handleInputChange} 
        />
      </label><br/>

      {/*<label>*/}
      {/*  Responsetijd in dagen:*/}
      {/*  <input */}
      {/*    type="number" */}
      {/*    name="responseDays" */}
      {/*    value={assessment.responseDays} */}
      {/*    onChange={handleInputChange} */}
      {/*  />*/}
      {/*</label><br/>*/}

      <button type="submit">Aanmaken</button><br/>
    </form>
  );
}

export default NewAssessmentForm;
