import React, { useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { fetchAssessments, loggedinUserData } from '../backendService';
import './NavMenu.css';

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [userData, setUserData] = useState([]);
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await loggedinUserData();
        setUserData(userData);
      } catch (error) {
        console.error("Er is een fout opgetreden bij het ophalen van gebruikersdata:", error);
      }
    };
  
    fetchUserData();
  }, [location.pathname]); 
  

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

    return (
      
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <div className="white-space"></div>
            <NavbarBrand tag={Link} to="/">
              <img src="https://humancore.nl/wp-content/uploads/2023/08/Human-Core-Logo_v3.png" alt="HumanCore Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex" isOpen={!collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} to="/">Home</NavLink>
                </NavItem>
                {/* Suppress menuitems when url is /participant or Homepage  */}
                {!location.pathname.startsWith('/participant') && location.pathname !== '/' && 
                <><NavItem>
                  <NavLink tag={Link} to="/manage/assessments">Assessments</NavLink>
                  </NavItem> 
                  <NavItem>
                    <NavLink tag={Link} to="/manage/questions">Vragen</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/dashboard">Dashboard</NavLink>
                  </NavItem>
                  {userData.isAdmin &&
                  <NavItem>
                    <NavLink tag={Link} to="/organisations">Organisaties</NavLink>
                  </NavItem>
                  } 
                </>
                }
                  
                  {location.pathname === '/' && <NavItem>
                    <NavLink tag={Link} to="/manage/assessments">Beheer</NavLink>
                  </NavItem>}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );    
    }
    export default NavMenu;