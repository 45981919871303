function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;
    
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="closebutton" onClick={onClose}>X</button>
          {children} 
        </div>
      </div>
    );
}

export default Modal;