import React, { useState } from 'react';
import { editQuestion } from '../../backendService';
import BaseQuestionForm from './BaseQuestionForm';

function EditQuestionForm({ questionToEdit, onFormSubmit, pages, lang }) {
  console.log('questionToEdit:', questionToEdit);

  const handleEditSubmit = async (question) => {
    
    const questionData = {
      ...question,
      name: question.title
    };
      
    await editQuestion(questionData);
  
    if (onFormSubmit) { onFormSubmit(); }
  }
  

  return (
  <>
  
    <h4>Wijzigen vraag</h4>
    <BaseQuestionForm initialData={questionToEdit} onSubmit={handleEditSubmit} pages={pages} lang={lang}/>
  
  </>
  );
}

export default EditQuestionForm;
